import React, { useMemo } from "react"
import styled from "styled-components"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"

const Wrapper = styled.div`
  max-width: 100%;

  overflow: auto;

  th,
  td {
    &[data-centered="true"] {
      text-align: center;
    }
  }

  th {
    font-size: 0.8rem;
    letter-spacing: -0.2px;
    line-height: 1em;
  }

  td {
    transition: background 0.2s ease-in-out;

    &[data-centered="true"] {
      border-left: 1px solid ${props => props.theme.color.darkBg};
    }

    .check {
      font-weight: bold;
    }
  }

  tr:hover td {
    background-color: ${props => props.theme.color.mediumBg};
  }

  .responsiveTable {
    td {
      &:not(:last-child) {
        border-bottom: 1px solid ${props => props.theme.color.darkBg} !important;
      }
    }

    .tdBefore {
      font-size: 0.8rem;
      line-height: 1em;
    }
  }
`

export default function PendingInfoTable(props) {
  const columns = useMemo(
    () => [
      { header: "Title", accessor: "title" },
      { header: "Country", accessor: "country" },
      { header: "Application No.", accessor: "applicationNumber" },
      { header: "Product", accessor: "product" },
    ],
    []
  )

  const data = useMemo(
    () => [
      [
        "BLENDER WITH ELEVATOR ASSEMBLY AND REMOVABLE SPINDLE",
        "European Patent",
        "EP21180882.9",
        "F'REAL 120 (PONY)",
      ],
      [
        "BLENDER WITH REMOVABLE SPINDLE AND MONITORED RESERVOIR",
        "Japan",
        "2024-78570",
        "F'REAL B7",
      ],
      [
        "BLENDER WITH REMOVABLE SPINDLE AND MONITORED RESERVOIR / NICARAGUA NATIONAL PHASE",
        "Nicaragua",
        "201700098",
        "F'REAL B7",
      ],
      [
        "BLENDER WITH REMOVABLE SPINDLE AND MONITORED RESERVOIR / THAILAND NATIONAL PHASE",
        "Thailand",
        "1701004367",
        "F'REAL B7",
      ],
      [
        "Compostable Cup for Food Preparation Machine",
        "Taiwan",
        "113130974",
        "f'Real cup",
      ],
      [
        "Magnetic Lid for Blending",
        "United States of America",
        "63/716,639",
        "F'REAL B9",
      ],
      [
        "MODULAR BLENDER WITH IMPROVED WATER HEATING AND LIGHT BEAM DETECTION",
        "United States of America",
        "17/156,952",
        "F'REAL B6",
      ],
      [
        "MODULAR BLENDER WITH IMPROVED WATER HEATING AND LIGHT BEAM DETECTION",
        "Indonesia",
        "P00202106052",
        "F'REAL B6",
      ],
      [
        "MODULAR BLENDER WITH IMPROVED WATER HEATING AND LIGHT BEAM DETECTION",
        "Singapore",
        "10202107765Y",
        "F'REAL B6",
      ],
      [
        "MODULAR BLENDER WITH IMPROVED WATER HEATING AND LIGHT BEAM DETECTION",
        "China",
        "2022101160290",
        "F'REAL B6",
      ],
      [
        "MODULAR BLENDER WITH IMPROVED WATER HEATING AND LIGHT BEAM DETECTION",
        "Malaysia",
        "PI2022005286",
        "F'REAL B6",
      ],
      [
        "MODULAR BLENDER WITH IMPROVED WATER HEATING AND LIGHT BEAM DETECTION",
        "New Zealand",
        "794518",
        "F'REAL B6",
      ],
      [
        "Modular blender with improved water heating and light beam detection",
        "Korea, Republic of (KR)",
        "10-2022-7042155",
        "F'REAL B6",
      ],
      [
        "Modular blender with improved water heating and light beam detection",
        "New Zealand",
        "794516",
        "F'REAL B6",
      ],
      [
        "PCT/MODULAR BLENDER WITH IMPROVED WATER HEATING AND LIGHT BEAM DETECTION",
        "Canada",
        "3042053",
        "F'REAL B6",
      ],
      [
        "PCT/MODULAR BLENDER WITH IMPROVED WATER HEATING AND LIGHT BEAM DETECTION",
        "Brazil",
        "1120190096094",
        "F'REAL B6",
      ],
      [
        "PCT/MODULAR BLENDER WITH IMPROVED WATER HEATING AND LIGHT BEAM DETECTION",
        "European Patent",
        "EP17870725.3",
        "F'REAL B6",
      ],
      [
        "PCT/MODULAR BLENDER WITH IMPROVED WATER HEATING AND LIGHT BEAM DETECTION",
        "New Zealand",
        "780286",
        "F'REAL B6",
      ],
    ],
    []
  )

  return (
    <Wrapper>
      <Table>
        <Thead>
          <Tr>
            {columns.map((column, colIndex) => (
              <Th key={colIndex} data-centered={colIndex > 1}>
                {column.header}
              </Th>
            ))}
          </Tr>
        </Thead>

        <Tbody>
          {data.map((row, rowIndex) => (
            <Tr key={`${rowIndex}`}>
              {columns.map((column, colIndex) => (
                <Td
                  key={`${rowIndex}-${colIndex}`}
                  data-centered={colIndex > 1}
                >
                  {row[colIndex] === "X" ? (
                    <span className="check">&times;</span>
                  ) : (
                    row[colIndex]
                  )}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Wrapper>
  )
}
